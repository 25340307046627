import { useEffect, useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import { capturefingerprint } from "../../../../common/fingurePrintJs/FingurePrint";
import { dailyAuth } from "../../../../api/login/Login";

import img1 from '../../../../asesets/logo/finger_success.gif'

import imgg1 from '../../../../asesets/Devices/iris.png'
import imgg2 from '../../../../asesets/Devices/mantra.png'
import imgg3 from '../../../../asesets/Devices/mantral1.png'
import imgg4 from '../../../../asesets/Devices/morpho.png'
import imgg5 from '../../../../asesets/Devices/morphol1.png'
import imgg6 from '../../../../asesets/Devices/precision.png'
import imgg7 from '../../../../asesets/Devices/sequgen.png'
import imgg8 from '../../../../asesets/Devices/startrek.png'
import imgg9 from '../../../../asesets/Devices/startrekl1.png'

const mockData = [
    { id: '1', image: imgg1, name: 'Iris' },
    { id: '1', image: imgg2, name: 'Mantra' },
    { id: '1', image: imgg3, name: 'Mantra L1' },
    { id: '1', image: imgg4, name: 'Morpho' },
    { id: '1', image: imgg5, name: 'Morpho L1' },
    { id: '1', image: imgg6, name: 'Precision' },
    { id: '1', image: imgg7, name: 'Secugen' },
    { id: '1', image: imgg8, name: 'Startrek' },
    { id: '1', image: imgg9, name: 'Startrek L1' },
]

function DailyAuth({ onDailyAuthComplete }) {
    const [position, setPosition] = useState({ latitude: null, longitude: null });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [initialAuth, setInitialAuth] = useState({
        accessmodetype: "site",
        latitude: "",
        longitude: "",
        data: "",
        ipaddress: "",
        user_id: ""
    });

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setPosition({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            });
        } else {
            showSnackbar('Geolocation is not available in your browser.', 'error');
        }
    }, []);

    const showSnackbar = (message, severity = 'success') => {
        setSnackbar({ open: true, message, severity });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    const handleDeviceClick = (device) => {
        console.log('Device clicked:', device);
        setSelectedDevice(device);
        showSnackbar(`Selected device: ${device.name}`);
        // Here you can add logic to initialize the selected device
    };

    const Capturefingerherw = () => {
        try {
            const fingerData = new capturefingerprint('http://127.0.0.1:11100/rd/capture', result)
            // console.log(fingerData);
        } catch (error) {
            console.log(error);
        }
    }

    const result = (data) => {
        console.log('Received data:', data);

        if (!data) {
            console.error('No data received in result function');
            showSnackbar('Error: No data received from fingerprint capture', 'error');
            return;
        }

        if (typeof data !== 'object') {
            console.error('Invalid data type received:', typeof data);
            showSnackbar('Error: Invalid data received from fingerprint capture', 'error');
            return;
        }

        const prolog = data.pid_data;
        if (!prolog) {
            console.error('No pid_data in received data');
            showSnackbar('Error: No fingerprint data received', 'error');
            return;
        }

        try {
            var parser = new DOMParser();
            const XmlStr = prolog + "<bookz/>";
            var xmlz = parser.parseFromString(XmlStr, "application/xml");

            if (data.errCode === '0') {
                showSnackbar('Finger Capture Successful');
            } else if (data.errCode) {
                showSnackbar(`Error in finger capture: ${data.errCode}`, 'error');
                return;
            }

            if (data.httpSuccess === true) {
                const encodedXml = window.btoa((new XMLSerializer()).serializeToString(xmlz));
                dataPost(encodedXml);
            } else {
                showSnackbar('HTTP request was not successful', 'error');
            }
        } catch (error) {
            console.error('Error processing fingerprint data:', error);
            showSnackbar('Error processing fingerprint data', 'error');
        }
    }

    const dataPost = async (fingerData) => {
        const clone = { ...initialAuth, latitude: position.latitude, longitude: position.longitude, data: fingerData, user_id: window.localStorage.getItem('userIdToken') }
        try {
          const res = await dailyAuth(clone)
          console.log(res);
          if (res?.data?.statusCode == 400) {
            showSnackbar(res?.data?.message || "Something went Wrong",'error')
          }
          if (res?.data?.statusCode == '403') {
            showSnackbar(res?.data?.message || "Something went Wrong",'error')
          }
          if (res?.data?.statusCode == 200) {  // Assuming 200 is the success status code
            showSnackbar('Daily Auth completed successfully')
            onDailyAuthComplete()  // Call the callback function
          }
        } catch (error) {
          console.error('Error in daily auth:', error)
          showSnackbar('An error occurred during daily auth','error')
        }
    };
      
    return (
        <>
            <div className="ContentArea">
                <div className="card">
                    <div className="card-header Register-header">
                        <span>Daily Auth</span>
                    </div>
                    <div className="card-body">
                        <div className="form-row">
                            <div className="col-lg-6">
                                <div className="bankResiter">
                                    <button type="button" className="btn btn-success" onClick={Capturefingerherw}>
                                        <img src={img1} alt="Capture Fingerprint" style={{ height: '526px' }} />
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    {mockData.map((item) => (
                                        <div className="col-lg-4 mt-2" key={item.id}>
                                            <div 
                                                className={`deviceImage ${selectedDevice?.id === item.id ? 'selected' : ''}`}
                                                onClick={() => handleDeviceClick(item)}
                                            >
                                                <img src={item.image} alt={item.name} />
                                                <p>{item.name}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Snackbar 
                    open={snackbar.open} 
                    autoHideDuration={6000} 
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            </div>
        </>
    )
}

export default DailyAuth