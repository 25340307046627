import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaRupeeSign } from "react-icons/fa";
import { sendMoneyTrans, verificationBank } from "../../../../api/login/Login";
import TifinPopup from "./tifinPopup/TifinPopUp";
import { useNavigate } from 'react-router-dom';

function UserTransfer(props) {
    const [modalShow, setModalShow] = useState(false);
    const [recipientData, setRecipientData] = useState(null);
    const [isVerified, setIsVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [storetid, setStoreTid] = useState(null);
    const [amountError, setAmountError] = useState('');
    const [channelKey, setChannelKey] = useState(1);
    const [initialMoney, setInitialMoney] = useState({
        user_id: '',
        recipient_id: '',
        amount: '',
        customer_mobile: '',
        channel: 'NEFT',
        latlong: '',
        beneficiary_name: '',
        bank_name: '',
        ifsc_code: '',
        account_number: '',
        customer_name: ''
    });

    const navigate = useNavigate();
    const resetInitialMoney = () => {
        setInitialMoney({
          user_id: '',
          recipient_id: '',
          amount: '',
          customer_mobile: '',
          channel: 'NEFT',
          latlong: '',
          beneficiary_name: '',
          bank_name: '',
          ifsc_code: '',
          account_number: '',
          customer_name: ''
        });
        setAmountError('');
      };

    useEffect(() => {
        setRecipientData(props.sendMoneyData);
        setIsVerified(props.sendMoneyData?.isVerified || false);
    }, [props.sendMoneyData]);


    const handleAmount = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        if (name === 'amount') {
            const amount = parseFloat(value);
            if (isNaN(amount) || amount < 100) {
                setAmountError('Minimum amount is 100');
            } else if (amount > props.maxamount) {
                setAmountError(`Maximum amount is ${props.maxamount}`);
            } else {
                setAmountError('');
            }
        }

        setInitialMoney(prev => ({ ...prev, [name]: value }));
    }

    const handleChangeChanel = (str) => {
        const key = str === 'IMPS' ? 2 : 1;
        setChannelKey(key);
        setInitialMoney(prev => ({ ...prev, channel: str }));
    }

    const [position, setPosition] = useState({ latitude: null, longitude: null });

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setPosition({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            });
        } else {
            alert('Geolocation is not available in your browser.')
        }
    }, []);

    const tifinPopupOpen = () => {
        props.onHide();
        setModalShow(true);
    }

    const handleEnterPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            props.onHide();
            setModalShow(true);
        }
    };

    const sendMoney = async (tipin) => {
        setIsLoading(true);
        const clone = {
            ...initialMoney,
            tpin: tipin,
            recipient_id: recipientData.recipient_id,
            customer_mobile: props.customerNumber,
            channel: channelKey,
            beneficiary_name: recipientData.bank_account_name || recipientData.recipient_name,
            bank_name: recipientData.bank,
            ifsc_code: recipientData.ifsc,
            account_number: recipientData.account,
            customer_name: props.customerinformation,
            bank_id: recipientData.bankId,
            api_id: props.initialCustomer.api_id,
            user_id: window.localStorage.getItem('userIdToken'),
            latlong: `${position.latitude},${position.longitude}`
        };

        try {
            const res = await sendMoneyTrans(clone);
            if (res?.data?.statusCode === '200') {
                props.toastSuccessMessage(res?.data?.message);
                const tid = res?.data?.data?.[0]?.batchId;
                setStoreTid(tid);
                setModalShow(false);
                if (props.walletShowHeader) props.walletShowHeader();
                setTimeout(() => {
                    navigate(`/deatils/${tid}`);
                }, 300);
            } else if (res?.data?.error === true) {
                props.toastSuccessMessageError(res?.data?.message);
            }
        } catch (error) {
            console.error("Error sending money:", error);
            props.toastSuccessMessageError("An error occurred while sending money.");
        } finally {
            setIsLoading(false);
        }
    }

    const VerifiedBank = async () => {
        setIsLoading(true);
        const clone = {
            name: recipientData.recipient_name,
            ifsc: recipientData.ifsc,
            bankAccount: recipientData.account,
            phone: recipientData.recipient_mobile,
            recipient_id: recipientData.recipient_id,
            bank_id: recipientData.bankId,
            api_id: props.initialCustomer.api_id,
            user_id: window.localStorage.getItem('userIdToken')
        };

        try {
            const res = await verificationBank(clone);
            if (res?.data?.statusCode === '200') {
                props.toastSuccessMessage(res?.data?.message);
                setRecipientData(prevData => ({
                    ...prevData,
                    bank_account_name: res.data.data.bank_account_name
                }));
                setIsVerified(true);
                props.updateRecipetList();
            } else {
                props.toastSuccessMessageError(res?.data?.message ||  "Something Went Wrong");
                setIsVerified(false);
            }
        } catch (error) {
            console.error("Error verifying bank:", error);
            props.toastSuccessMessageError("An error occurred while verifying the bank account.");
            setIsVerified(false);
        } finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        return () => {
          resetInitialMoney();
        };
      }, []);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-dialog-modal"
                onHide={() => {
                    resetInitialMoney();
                    props.onHide();
                  }}
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className="head-modal text-align-center">
                            <h2>Send Money</h2>
                            <p>Domestic Money Transfer</p>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="send-money-deatils">
                        <div className="send-money-name">
                            <h2>Name : </h2>
                            <p>{isLoading ? "Verifying..." : recipientData?.bank_account_name || recipientData?.recipient_name}</p>
                        </div>
                        {recipientData?.recipient_mobile && (
                            <div className="send-money-Mobile">
                                <h2>Mobile : </h2>
                                <p>{recipientData.recipient_mobile}</p>
                            </div>
                        )}
                        <div className="send-money-accNum">
                            <h2>IFSC Code : </h2>
                            <p>{recipientData?.ifsc}</p>
                        </div>
                        <div className="send-money-accNum">
                            <h2>Account Number : </h2>
                            <p>{recipientData?.account}</p>
                        </div>
                        <div className="send-money-bnkName">
                            <h2>Bank Name : </h2>
                            <p>{recipientData?.bank}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="send-moneyForm mt-1">
                                <form action="">
                                    <div className="input-group mb-3">
                                        <span className="input-group-text" id="basic-addon1"><FaRupeeSign /></span>
                                        <input 
                                            type="number" 
                                            className={`form-control ${amountError ? 'is-invalid' : ''}`}
                                            placeholder={`Enter Amount (Min: 100, Max: ${props.maxamount})`} 
                                            name="amount" 
                                            onKeyPress={handleEnterPress} 
                                            value={initialMoney.amount} 
                                            onChange={handleAmount} 
                                        />
                                    </div>
                                    {amountError && <div className="invalid-feedback d-block mb-1">{amountError}</div>}
                                    <button 
                                        type="button"
                                        className={`btn ${!initialMoney?.amount || amountError ? 'commonbotton_disable' : 'btn-success'} btn-cnf`} 
                                        disabled={!initialMoney?.amount || amountError || isLoading} 
                                        onClick={tifinPopupOpen}
                                    >
                                        Submit
                                        {isLoading && <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>}
                                    </button>
                                    <div className="mode-sec">
                                        <h4>Select Transfer Mode</h4>
                                        <div className="selctMode mt-3">
                                            <div className="form-check me-3">
                                                <input
                                                    className="form-check-input form-check-input-2"
                                                    type="radio"
                                                    name="channel"
                                                    id="IMPS"
                                                    onChange={() => handleChangeChanel('IMPS')}
                                                />
                                                <label className="form-check-label mt-1 ms-2" htmlFor="IMPS">
                                                    IMPS
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input form-check-input-2"
                                                    type="radio"
                                                    name="channel"
                                                    id="NEFT"
                                                    onChange={() => handleChangeChanel('NEFT')}
                                                    defaultChecked={true}
                                                />
                                                <label className="form-check-label mt-1 ms-2" htmlFor="NEFT">
                                                    NEFT
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="money-footer-button">
                        <Button 
                            disabled={isVerified || isLoading} 
                            onClick={VerifiedBank} 
                            className={`${isVerified || isLoading ? 'commonbotton_disable' : ''} mr-3`}
                        >
                            {isLoading ? "Verifying..." : "Verify Account"}
                        </Button>
                        <Button onClick={props.onHide}>Cancel</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <TifinPopup
                show={modalShow}
                onHide={() => setModalShow(false)}
                setModalShow={setModalShow}
                sendMoney={sendMoney}
                sendMoneyData={recipientData}
                handleAmount={handleAmount}
                initialMoney={initialMoney}
                setInitialMoney={setInitialMoney}
                loading3={isLoading}
                amountError={amountError}
                maxamount={props.maxamount}
            />
        </>
    );
}

export default UserTransfer;