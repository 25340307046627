import React, { useEffect } from 'react';

const AntiInspect = ({ children }) => {
  useEffect(() => {
    const preventInspect = () => {
      // Disable right-click
      document.addEventListener('contextmenu', (e) => e.preventDefault());

      // Disable F12 key
      document.addEventListener('keydown', (e) => {
        if (e.key === 'F12') {
          e.preventDefault();
          return false;
        }
      });

      // Disable Ctrl+Shift+I (Chrome)
      document.addEventListener('keydown', (e) => {
        if (e.ctrlKey && e.shiftKey && e.key === 'I') {
          e.preventDefault();
          return false;
        }
      });

      // Clear console and add warning
      setInterval(() => {
        console.clear();
        console.log(
          '%cStop!',
          'color: red; font-size: 30px; font-weight: bold;'
        );
        console.log(
          '%cThis is a browser feature intended for developers.',
          'font-size: 18px;'
        );
      }, 1000);
    };

    preventInspect();

    return () => {
      // Clean up event listeners if needed
      document.removeEventListener('contextmenu', preventInspect);
      document.removeEventListener('keydown', preventInspect);
    };
  }, []);

  return <>{children}</>;
};

export default AntiInspect;