import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Pagination,
  Typography,
  styled,
  CircularProgress
} from '@mui/material';
import {
  Search,
  Clear,
  Visibility,
  FileDownload
} from '@mui/icons-material';
import { utilityReport, paymentEnquiry ,utilityReportall} from "../../../api/login/Login";
import { handleBbpsInquiry } from '../../../components/admin/fastTagRecharge/fastTagRechargeList/BbpsInquiryfunction';
import { useNavigate } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.action.hover,
}));

function UtilityReport() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [count] = useState(10);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const [filterInitial, setFilterInitial] = useState({
    biller_id: '',
    start_date: getCurrentDate(),
    end_date: getCurrentDate(),
    min_amt: '',
    max_amt: '',
  });

  const handleFilterChange = (event) => {
    setFilterInitial({
      ...filterInitial,
      [event.target.name]: event.target.value,
    });
  };

  const getTransitionReport = async (pageNum) => {
    setLoading(true);
    try {
      const params = {
        ...filterInitial,
        count,
        page: pageNum,
      };

      const cleanParams = Object.entries(params).reduce((acc, [key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
          acc[key] = value;
        }
        return acc;
      }, {});

      const res = await utilityReport(cleanParams);
      setTotalCount(res?.data?.totalCount || 0);
      setData(res?.data?.data|| []); // Make sure this matches the actual structure of your API response
    } catch (error) {
      console.error('Error fetching transition report:', error);
      setData([]);
    }
    setLoading(false);
  };

  const handlePageChange = (event, value) => {
    setPage(value - 1);
    getTransitionReport(value - 1);
  };

  const handleSearch = () => {
    setPage(0);
    getTransitionReport(0);
  };

  const handleReset = () => {
    setFilterInitial({
      biller_id: '',
      start_date: "",
      end_date: "",
      min_amt: '',
      max_amt: '',
    });
    setPage(0);
    getTransitionReport(0);
  };

  const responseEnquiry = async (id) => {
    try {
      await handleBbpsInquiry(id, setLoading2);
    } catch (error) {
      console.error('Error in responseEnquiry:', error);
    }
  };
  const handleViewInvoice = (id) => {
    navigate(`/bill-invoice/${id}`);
  };
  const handleDownloadExcel = async () => {
    try {
      const response = await utilityReportall(filterInitial);
      // Assuming the response contains a URL to the Excel file
      const url = response.data.fileUrl;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'utility_report.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);  
    } catch (error) {
      console.error('Error downloading Excel:', error);
    }
  };

  useEffect(() => {
    getTransitionReport(0);
  }, []);
  return (
    <>
    <Paper sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Utility Report</Typography>
      <Grid item xs={12} >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 3 ,mb:2}}>
      {/* <Button 
          startIcon={<FileDownload />} 
          variant="contained" 
          color="success"
          onClick={handleDownloadExcel}
        >
          Download Excel
        </Button> */}
        </Box>
        
      </Grid>
      
      <Grid container spacing={3} alignItems="center" sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Biller ID"
            name="biller_id"
            value={filterInitial.biller_id}
            onChange={handleFilterChange}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Start Date"
            type="date"
            name="start_date"
            value={filterInitial.start_date}
            onChange={handleFilterChange}
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="End Date"
            type="date"
            name="end_date"
            value={filterInitial.end_date}
            onChange={handleFilterChange}
            fullWidth
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid container spacing={3} alignItems="center" sx={{ mb: 4 ,mt:2,ml:4}}>
        <Grid item xs={12} sm={3} md={3}>
          <Box display="flex" justifyContent="space-between">
            <Button startIcon={<Search />} variant="contained" onClick={handleSearch} sx={{ mr: 1, flex: 1 }}>
              Search
            </Button>
            <Button startIcon={<Clear />} variant="outlined" onClick={handleReset} sx={{ flex: 1 }}>
              Reset
            </Button>
          </Box>
        </Grid>
        </Grid>
      </Grid>

      <Paper sx={{ p: 3, mb: 3 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <StyledTableCell>S.No</StyledTableCell>
                <StyledTableCell>Transaction Date</StyledTableCell>
                <StyledTableCell>Txn Id</StyledTableCell>
                <StyledTableCell>Biller Id</StyledTableCell>
                <StyledTableCell>Service Name</StyledTableCell>
                <StyledTableCell align="right">Amount</StyledTableCell>
                <StyledTableCell>Response</StyledTableCell>
                <StyledTableCell>View</StyledTableCell>
                <StyledTableCell>Inquiry</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : Array.isArray(data) && data.length > 0 ? (
                  data.map((row, index) => (
                  <TableRow key={row._id} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#fafafa' } }}>
                    <TableCell>{index + 1 + (page * count)}</TableCell>
                    <TableCell>{row.createdAt}</TableCell>
                    <TableCell>{row.txn_id}</TableCell>
                    <TableCell>{row.biller_id}</TableCell>
                    <TableCell>{row.service_name}</TableCell>
                    <TableCell align="right">₹{row.amount}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        size="small"
                        color={row.status === 2 ? 'success' : row.status === 1 ? 'warning' : 'error'}
                        sx={{ borderRadius: '20px', textTransform: 'capitalize' }}
                      >
                        {row.status === 2 ? 'Success' : row.status === 1 ? 'Pending' : 'Failed'}
                      </Button>
                    </TableCell>
                    <TableCell>
                    <IconButton onClick={() => handleViewInvoice(row._id)} color="primary">
                        <Visibility />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => responseEnquiry(row._id)}
                        sx={{ borderRadius: '20px' }}
                        disabled={loading2}
                      >
                        Inquiry
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">No records found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 3 }}>
       
      <Pagination
          count={Math.ceil(totalCount / count)}
          page={page + 1}
          onChange={handlePageChange}
          color="primary"
          size="large"
        />
      </Box>
    </Paper>
    </>
  );
}

export default UtilityReport;