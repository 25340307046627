import { useEffect, useState } from "react"
import { aepsOnboard, UserPermissionAeps } from "../../../api/login/Login"
import CashDeposit from "../../../components/admin/cashDiposit/CashDeposit"
import AepsDipositPurchge from "./aepsDipositPurchage/AepsDipositPurchage"
import CasDepositTab from "./casDepositTab/CasDepositTab"
import PermissionAepsOnboarding from "../../../components/admin/aepsOnboarding/permissionAepsOnboarding/PermissionAepsOnboarding"
import SuggestOnboarding from "../../../components/admin/aepsOnboarding/suggetsOnboarding/SuggestOnboarding"
import BankPermission from "../../../components/admin/aepsOnboarding/bankPermission/BankPermission"
import AuthResister from "../../../components/admin/aepsOnboarding/authResister/AuthResister"
import DailyAuth from "../../../components/admin/aepsOnboarding/dailyAuth/DailyAuth"
import { toast, ToastContainer } from "react-toastify"
import Bank4aepsverify from "../aepsOnboarding/Bank4aepsverify"
import { toastSuccessMessage } from "../../../components/compeleteRegister/ToastShare"
import { Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


function CashDepositPage({profiledata}) {

    const [loder, setLoder] = useState(false)

    const [isPlanPurchage, SetisPlanPurchage] = useState(false)
    const [bankverify, setbankverify] = useState(false)

    const [onBoading, setOnBoarding] = useState(false)
    const [activeMessage, setActiveMessage] = useState()
    const [buyMessage, setBuyMessage] = useState()

    const [bank2, setbank2] = useState()
    const [bank3, setbank3] = useState()

    const [suggestOnBoard, setSuggestOnBoarding] = useState(false)
    const [showContactAdmin, setShowContactAdmin] = useState(false)

    const [bankPermission, setBankPermission] = useState(false)
    const [authRegistered, setAuthRegistered] = useState(false)
    const [dailyAuth, setDailyAuth] = useState(false)
    const [dailyAuthCompleted, setDailyAuthCompleted] = useState(false)
    const [bankServerDown, setBankServerDown] = useState(false);
const [isInstantpayOnBoarded, setIsInstantpayOnBoarded] = useState(null)
const [merchnadecode, setMerchantCode] = useState(null)
const [openInstantpayOnBoarded, setOpenIsInstantpayOnBoarded] = useState(false)

const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "error" });
const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };
  
  const showSnackbar = (message, severity = "error") => {
    setSnackbar({ open: true, message, severity });
  };

    const permissionAeps = async () => {
        setLoder(true)
        try {
            const res = await UserPermissionAeps()
            setMerchantCode(res.data.data.merchantCode || "");
            let conditionMet = false
            if ('isInstantpayOnBoarded' in res?.data?.data) {
                console.log("isInstantpayOnBoarded found in response");
                setIsInstantpayOnBoarded(res.data.data.isInstantpayOnBoarded);
                // setMerchantCode(res.data.data.merchantCode);
                
                if (res.data.data.isInstantpayOnBoarded === false) {
                    console.log("User is not onboarded with Instantpay yet");
                    setOnBoarding(true)
                    setSuggestOnBoarding(true)
                    setBankPermission(true)
                    setAuthRegistered(true)
                    setDailyAuth(true); 
                    setbankverify(false);
                    conditionMet = true
                } else if (res.data.data.isInstantpayOnBoarded === true && res.data.data.authRegistered === true) {
                    console.log("User is onboarded with Instantpay and auth registered");
                    setOnBoarding(true)
                    setSuggestOnBoarding(true)
                    setBankPermission(true)
                    setAuthRegistered(true)
                    setDailyAuth(true);
                    setbankverify(false);
                    conditionMet = true
                }
                else if (
                    res?.data?.statusCode == '007'
                    
                ) {
                    setbankverify(true);
                    conditionMet = true
                }
                else if (res?.data?.statusCode == '00127') {
                    setBankServerDown(true);
                    conditionMet = true
                    setLoder(false);
                    return; // Exit the function early
                }
            } 

            if (
                res?.data?.statusCode == '007'
                
               
            ) {
                setbankverify(true);  
                conditionMet = true
            }
            if (res?.data?.statusCode == '00127') {
                setBankServerDown(true);
                setLoder(false);
                conditionMet = true
                return; // Exit the function early
            }
            // console.log(res?.data?.data);
            // if (res?.data?.data?.is_active == false) {
            //     setActiveMessage('Please Admin Side Active User')
            // }
            // if (res?.data?.data?.is_active == true) {
            //     if (res?.data?.data?.is_buy == false) {
            //         setBuyMessage('Please Purchase')
            //     }
            // }
            // if (res?.data?.data?.is_active == true && res?.data?.data?.is_buy == true) {
            //     setOnBoarding(true)
            // }

            // if (res?.data?.data?.isOnBoarded == false) {
            //     // onBoardingApiCall()
            //     setSuggestOnBoarding(true)
            // }
            // if (res?.data?.data?.isOnBoarded == true) {
            //     setBankPermission(true)
            // }

            // // bankPermission/////
            // if (res?.data?.data?.isOnBoarded == true) {
            //     if (!res?.data?.data?.bank2 || !res?.data?.data?.bank3) {
            //         setbank2('AEPS OnBoarding Side is Pending')
            //         // setbank2('AEPS OnBoarding Side is Pending Bank 2')
            //         // setSuggestOnBoarding(false)
            //     }
            // }

            // // if (res?.data?.data?.bank2 == true) {
            // //     if (res?.data?.data?.bank3 == false) {
            // //         setbank3('AEPS OnBoarding Side is Pending ')
            // //         // setbank3('AEPS OnBoarding Side is Pending Bank 3')
            // //         // setSuggestOnBoarding(false)
            // //     }
            // // }

            // if (res?.data?.data?.bank2 == true || res?.data?.data?.bank3 == true) {
            //     // console.log('setBankPermission == true');

            //     if (res?.data?.data?.authRegistered == false) {
            //         setBankPermission(true)
            //     }
            // }

            // if (res?.data?.data?.authRegistered == true) {
            //     setBankPermission(true)
            // }

            // if (res?.data?.data?.authRegistered == true) {
            //     if (res?.data?.data?.dailyAuth == false) {
            //         setBankPermission(true)
            //     }
            // }

            // bankPermission/////

            // if (res?.data?.data?.is_active == false && res?.data?.data?.is_buy == false) {
            //     setActiveMessage('Please Admin Side Active User')

            // }
            

            if (res?.data?.data?.is_active == false) {
                setActiveMessage('Please Admin Side Active User')
                conditionMet = true

            }
            if (res?.data?.data?.is_active == true) {
                if (res?.data?.data?.is_buy == false) {
                    setBuyMessage('Please Purchase')
                    conditionMet = true
                }
            }




            if (res?.data?.data?.is_active == true && res?.data?.data?.is_buy == true) {
                SetisPlanPurchage(true)
                setOnBoarding(true)
                setSuggestOnBoarding(false)
                setBankPermission(true)
                setAuthRegistered(true)
                setDailyAuth(true)
                conditionMet = true
            }

            if (res?.data?.data?.isOnBoarded == true) {
                setOnBoarding(true)
                setSuggestOnBoarding(true)
                setBankPermission(false)
                setAuthRegistered(true)
                setDailyAuth(true)
                conditionMet = true
            }

            if (res?.data?.data?.bank2 == true || res?.data?.data?.bank3 == true ) {
                setOnBoarding(true)
                setSuggestOnBoarding(true)
                setBankPermission(true)
                setAuthRegistered(false)
                setDailyAuth(true)
                conditionMet = true
            }

            if (res?.data?.data?.authRegistered == true) {
                setOnBoarding(true)
                setSuggestOnBoarding(true)
                setBankPermission(true)
                setAuthRegistered(true)
                setDailyAuth(false)
                conditionMet = true
            }


            if (res?.data?.data?.dailyAuth == true) {
                setOnBoarding(true)
                setSuggestOnBoarding(true)
                setBankPermission(true)
                setAuthRegistered(true)
                setDailyAuth(true);
                setDailyAuthCompleted(true);
                conditionMet = true

            }



            // if (res?.data?.data?.isOnBoarded == true) {
            //     setOnBoarding(false)

            // }







            if (!conditionMet) {
                setShowContactAdmin(true)
            }

            setLoder(false)
        } catch (error) {
            setLoder(false)
        }
    }

 

    const handleDailyAuthCompletion = () => {
        setDailyAuthCompleted(true);
        setDailyAuth(true);
        permissionAeps();  // Re-fetch permissions to ensure all states are up-to-date
    }

    const onBoardingApiCall = async () => {
        const data = { user_id: window.localStorage.getItem('userIdToken') }
        try {
            const res = await aepsOnboard(data)
            // console.log(res);
            if (res?.data?.statusCode == '403') {
                showSnackbar(res?.data?.message  || "Something Went Wrong", "error");
            }
            if (res?.data?.statusCode == 200) {
                window.open(res?.data?.data?.url, '_blank')
            }
        } catch (error) {
            showSnackbar("An error occurred during onboarding", "error");

        }
    }


    useEffect(() => {
        permissionAeps()
    }, [])
    // console.log(suggestOnBoard);
    return (
        <>
            {showContactAdmin ? (
                <div style={{
                    textAlign: 'center',
                    padding: '20px',
                    backgroundColor: '#f0f0f0',
                    borderRadius: '5px',
                    margin: '20px auto',
                   
                }}>
                    <p>We're unable to process your request at this time. Please contact the administrator at <strong>1800 889 0178</strong> for assistance.</p>
                </div>
            ) : (
                <>
                    {/* Existing conditional rendering */}
                    {!isPlanPurchage ? <PermissionAepsOnboarding activeMessage={activeMessage} buyMessage={buyMessage} head='AEPS Cash Deposit' /> : <>
                        {suggestOnBoard ? '' : <SuggestOnboarding onBoardingApiCall={onBoardingApiCall} isInstantpayOnBoarded={isInstantpayOnBoarded}  merchnadecode={merchnadecode} setbankverify={setbankverify} permissionAeps={permissionAeps} />}

                        {bankPermission ? '' : <BankPermission bank2={bank2} bank3={bank3}  onDailyAuthComplete={handleDailyAuthCompletion}  profiledata ={profiledata} merchnadecode={merchnadecode} />}

                        {authRegistered ? '' : <AuthResister />}

                        {dailyAuth  || dailyAuthCompleted ? '' : <DailyAuth onDailyAuthComplete={handleDailyAuthCompletion}  />}
                        {suggestOnBoard && bankPermission && authRegistered && (dailyAuth || dailyAuthCompleted) ? <>
                            <CasDepositTab  isInstantpayOnBoarded={isInstantpayOnBoarded}/></> : ''}
                    </>}
                </>
            )}

            {bankServerDown && !showContactAdmin ? (
                <div style={{ 
                    textAlign: 'center', 
                    padding: '20px', 
                    backgroundColor: '#ffcccc', 
                    borderRadius: '5px',
                    margin: '20px auto',
                    maxWidth: '400px'
                }}>
                    <h2 style={{ color: '#cc0000' }}>Bank Server is Down</h2>
                    <p>We apologize for the inconvenience. Please try again later.</p>
                </div>
            ) : null}

            {bankverify && !showContactAdmin && <Bank4aepsverify
                show={bankverify}
                onHide={() => setbankverify(false)}
                permissionAeps={permissionAeps} />}

<Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
        </>
    )
}
export default CashDepositPage