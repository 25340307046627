import React, { useState } from 'react';
import { Button, CircularProgress, Snackbar, Alert } from '@mui/material';
import { onBoadingbank4 } from '../../../../api/login/Login';

const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';
  let cleanedNumber = phoneNumber.startsWith('+91') ? phoneNumber.slice(3) : phoneNumber;
  cleanedNumber = cleanedNumber.replace(/\D/g, '');
  return cleanedNumber;
};

function Instanpayonboarding({ merchnadecode ,setbankverify ,permissionAeps}) {
    console.log("merchnadecode",merchnadecode)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successpopup, setsuccesspopup] = useState(null);
    const [success, setSuccess] = useState(false);

    const phoneNumber = localStorage.getItem('regisNumber');
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

    const handleProceed = async () => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const response = await onBoadingbank4({
                // merchantCode: merchnadecode,
                // mobileNumber: phoneNumber
            });

            if (response.data.statusCode == '200') {
                setSuccess(true);
                setsuccesspopup(response.data.message)
                if (permissionAeps) {
                    setTimeout(() => {
                        permissionAeps();
                    }, 1000);
                }
          
            }
            else if(response.data.statusCode == '007'){
                setSuccess(true);
                setsuccesspopup(response.data.message)
                setbankverify(true)

            }
            
            else {
                setError(response.data.message || 'An error occurred during onboarding');
            }
        } catch (err) {
            console.error('Error during onboarding:', err);
            setError(err.response?.data?.message || 'An unexpected error occurred');
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setError(null);
        setSuccess(false);
    };

    return (
        <>
            <div className="ContentArea">
                <div className="card">
                    <div className="card-header"><span>Onboarding  Bank4</span></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                             
                                <div className="alert alert-info mt-4">
                                    <blockquote className="blockquote text-center mb-0">
                                        <p className="mb-0">
                                            "For now, we need an L1 device for AEPS for a few days because we will provide you with options for all devices after a few days. Thank you"
                                        </p>
                                    </blockquote>
                                </div>
                                <div className="btn-section">
                                    <Button 
                                        variant="contained" 
                                        color="success" 
                                        onClick={handleProceed}
                                        disabled={loading}
                                    >
                                        {loading ? <CircularProgress size={24} /> : 'Proceed'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Snackbar open={!!error || success} autoHideDuration={6000} onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleCloseSnackbar} severity={error ? "error" : "success"} sx={{ width: '100%' }}>
                    {error }
                    {successpopup}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Instanpayonboarding