import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert, Spinner } from "react-bootstrap";
import { Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { resendbank3otp } from "../../../api/login/Login";

const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';
  let cleanedNumber = phoneNumber.startsWith('+91') ? phoneNumber.slice(3) : phoneNumber;
  cleanedNumber = cleanedNumber.replace(/\D/g, '');
  return cleanedNumber;
};

function Bank4aepsverify({ show, onHide, permissionAeps }) {
  const [otp, setOtp] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [isResending, setIsResending] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  const phoneNumber = localStorage.getItem('regisNumber');
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    } else {
      setResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [resendTimer]);

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 6);
    setOtp(value);
    setError("");
  };

  const otpSubmit = async () => {
    if (otp.length !== 6) {
      setError("Please enter a 6-digit OTP");
      return;
    }
    setIsSubmitting(true);
    setError("");
    try {
      const response = await resendbank3otp({
        mobile: formattedPhoneNumber,
        user_id: window.localStorage.getItem("userIdToken"),
        otp: otp,
      });
      if (response?.data?.statusCode === 200) {
        setSnackbar({ open: true, message: response?.data?.message, severity: "success" });
        if (permissionAeps) {
          setTimeout(() => {
            permissionAeps();
          }, 1000);
        }
        onHide();
      } else {
        console.log("dshhjsdhjsdh")
        setSnackbar({ open: true, message: response?.data?.message  || "Verification failed", severity: "error" });
        setError(response?.data?.message  || "Verification failed");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      setSnackbar({ open: true, message: "An error occurred. Please try again.", severity: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const resendOtp = async () => {
    setResendDisabled(true);
    setResendTimer(30);
    setIsResending(true);
    try {
      const response = await resendbank3otp({
        mobile: formattedPhoneNumber,
        user_id: window.localStorage.getItem("userIdToken"),
      });
      if (response?.data?.statusCode == 200) {
        setSnackbar({ open: true, message: response?.data?.message || "OTP resent successfully", severity: "success" });
      } else {
        setSnackbar({ open: true, message: response?.data?.message || "Failed to resend OTP", severity: "error" });
      }
    } catch (error) {
      setSnackbar({ open: true, message: "An error occurred while resending OTP", severity: "error" });
    } finally {
      setIsResending(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="otp-customer"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">OTP Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="otpInput">
              <Form.Label>Enter OTP</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter 6-digit OTP"
                value={otp}
                onChange={handleChange}
                isInvalid={!!error}
                maxLength={6}
              />
              <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            </Form.Group>
          </Form>
          {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button variant="primary" onClick={otpSubmit} disabled={isSubmitting || otp.length !== 6}>
            {isSubmitting ? <><Spinner animation="border" size="sm" /> Verifying...</> : "Verify OTP"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
}

export default Bank4aepsverify;