import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BENEFICIARYAdd, bankListApiwithid, verificationBank ,getAllBank} from "../../../../api/login/Login";
import Select from 'react-select';
import { toast } from "react-toastify"

function AddNewBeneficiary(props) {
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [bankData, setBankData] = useState(null);
    const [bankSelected, setBankSelected] = useState(null);
    const [isVerified, setIsVerified] = useState(false);
    const [initialValue, setInitialValue] = useState({
        user_id: '',
        recipient_name: '',
        customer_mobile: '',
        account_number: '',
        ifsc: '',
        bank_code: '',
        bank_id: '',
        recipient_mobile: '',
        isVerified: false
    });
    

    const bankIdForList = props?.initialCustomer?.api_id;

   
    const bankList = async () => {
        try {
            
            let res;
            if (bankIdForList == "66bca8b95727c7563ad6e315") {
                res = await getAllBank();
            } else {
                res = await bankListApiwithid(bankIdForList);
            }
            if (res?.data?.data && Array.isArray(res.data.data)) {
                const mapped = res.data.data.map((item) => ({
                    ...item,
                    label: item.bank_name,
                    value: item.bankID
                }));
                setBankData(mapped);
                console.log("bankData",bankData)
            } else {
                console.error("Invalid data structure received from API");
                setBankData([]);  // Set to an empty array if data is invalid
            }
        } catch (error) {
            console.error("Error fetching bank list:", error);
            setBankData([]);  // Set to an empty array in case of error
        }
    };
    const findIfacCode = (bank_id) => {
        console.log(bank_id);
        const findIfac = bankData.find((item) => {
            return item?.bankID == bank_id
        })
        const clone = { ...initialValue, ifsc: findIfac?.ifsc_code }
        // console.log(findIfac?.ifsc_code);
        setInitialValue(clone)
        console.log(clone);
    }

    const changeHandleBeneficiary = (e) => {
        const { name, value } = e.target;
        setInitialValue(prev => ({
            ...prev,
            [name]: name === 'recipient_name' ? value.replace(/[^a-zA-Z\s]/g, '') : value
        }));
    };

    const bankChange = (selectedOption) => {
        setBankSelected(selectedOption);
        setInitialValue(prev => ({
            ...prev,
            ifsc: selectedOption?.ifsc_code === 'NULL' ? "" : selectedOption?.ifsc_code,
            bank_name: selectedOption?.bank_name,
            bank_id: selectedOption?.value
        }));
    };

    const HandleSubmitBeneficiary = async () => {
        setLoading1(true);
        const submissionData = {
            ...initialValue,
            customer_mobile: props.customerNumber,
            recipient_mobile: props.customerNumber,
            bank_code: bankSelected?.bankID,
            bank_id: bankSelected?._id,
            api_id: props.initialCustomer.api_id,
            user_id: window.localStorage.getItem('userIdToken')
        };

        try {
            const res = await BENEFICIARYAdd(submissionData);
            if (res?.data?.statusCode === 200) {
                props.toastSuccessMessage(res?.data?.message);
                props.updateRecipetList();
                resetForm();
                props.onHide();
            } else {
                props.toastSuccessMessageError(res?.data?.message || res?.message || "Something Went Wrong" );
            }
        } catch (error) {
            console.error("Error submitting beneficiary:", error);
            props.toastSuccessMessageError("An error occurred while adding the beneficiary.");
        }
        setLoading1(false);
    };

    const verifyBank = async () => {
        setLoading(true);
        const verificationData = {
            ...initialValue,
            name: initialValue.recipient_name,
            customer_mobile: props.customerNumber,
            bankAccount: initialValue.account_number,
            phone: props.customerNumber,
            bank_id: bankSelected?._id,
            user_id: window.localStorage.getItem('userIdToken')
        };

        try {
            const res = await verificationBank(verificationData);
            if (res?.data?.statusCode === '200') {
                setIsVerified(true);
                props.toastSuccessMessage(res?.data?.message);
                setInitialValue(prev => ({
                    ...prev,
                    recipient_name: res?.data?.data?.bank_account_name,
                    isVerified: true
                }));
            } else {
                props.toastSuccessMessageError(res?.data?.message);
            }
        } catch (error) {
            console.error("Error verifying bank:", error);
            props.toastSuccessMessageError("An error occurred during bank verification.");
        }
        setLoading(false);
    };

    const resetForm = () => {
        setInitialValue({
            user_id: '',
            recipient_name: '',
            customer_mobile: '',
            account_number: '',
            ifsc: '',
            bank_code: '',
            bank_id: '',
            recipient_mobile: '',
            isVerified: false
        });
        setBankSelected(null);
        setIsVerified(false);
        setBankData(null);
    };
    

    useEffect(() => {
        return () => {
            setInitialValue({
                recipient_name: '',
                customer_mobile: '',
                account_number: '',
                ifsc: '',
                bank_code: '',
                bank_id: '',
                recipient_mobile: ''
            });
            setBankSelected(null);
        };
    }, [props]);

    const isFormValid = initialValue.recipient_name.trim() && 
                        initialValue.ifsc && 
                        initialValue.account_number && 
                        bankSelected;

    const isVerifyDisabled = !isFormValid || isVerified;
    const isSubmitDisabled = !isFormValid ;
    useEffect(() => {
        if (props.show) {
            resetForm();
            bankList();  // Fetch bank list when modal is opened
        }
    }, [props.show, bankIdForList]);

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            onExit={resetForm}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    ADD NEW BENEFICIARY
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group">
                            <div className="fl-wrap fl-wrap-input">
                                <label htmlFor="BeneficiaryName" className="fl-label">Beneficiary Name <span style={{ color: 'red' }}>*</span></label>
                                <input 
                                    type="text" 
                                    className="form-control fl-input" 
                                    id="BeneficiaryName" 
                                    placeholder="Beneficiary Name" 
                                    name="recipient_name" 
                                    value={initialValue.recipient_name} 
                                    onChange={changeHandleBeneficiary} 
                                    readOnly={isVerified}
                                    maxLength="100"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-3">
                        <div className="mb-3">
                            <label htmlFor="BeneficiaryBank" className="form-label">Bank <span style={{ color: 'red' }}>*</span></label>
                            <Select
                                value={bankSelected}
                                name="bankSelected"
                                options={bankData || []}
                                className="games-dropdown-2 customsection"
                                classNamePrefix="select"
                                onChange={bankChange}
                                placeholder="Select Bank"
                                isDisabled={isVerified}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <div className="fl-wrap fl-wrap-input">
                                <label htmlFor="IFSCCode" className="fl-label">IFSC Code <span style={{ color: 'red' }}>*</span></label>
                                <input 
                                    type="text" 
                                    className="form-control fl-input" 
                                    id="IFSCCode" 
                                    placeholder="Enter IFSC Code" 
                                    name="ifsc" 
                                    value={initialValue.ifsc} 
                                    onChange={changeHandleBeneficiary} 
                                    readOnly={isVerified}
                                    maxLength="25"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group">
                            <div className="fl-wrap fl-wrap-input">
                                <label htmlFor="AccountNumber" className="fl-label">Account Number <span style={{ color: 'red' }}>*</span></label>
                                <input 
                                    type="number" 
                                    className="form-control fl-input" 
                                    id="AccountNumber" 
                                    placeholder="Enter Account Number" 
                                    name="account_number" 
                                    value={initialValue.account_number} 
                                    onChange={changeHandleBeneficiary}  
                                    readOnly={isVerified}
                                    maxLength="30"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <p style={{ color: 'green', fontWeight: '500' }}>
                            Account Verification Charge: ₹ {props.settingState || 0}
                        </p>
                        <div className="addBneButton">
                            <div className="form-group text-align-center">
                                <button 
                                    type="button" 
                                    disabled={isVerifyDisabled} 
                                    className={`btn ${isVerifyDisabled ? 'commonbotton_disable' : 'btn-info'}`}   
                                    onClick={verifyBank}
                                >
                                    {loading ? (
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    ) : 'Account Verification'}
                                </button>
                            </div>
                            <div className="form-group text-align-center">
                                <button 
                                    type="button" 
                                    disabled={isSubmitDisabled}  
                                    className={`btn ${isSubmitDisabled? 'commonbotton_disable' : 'btn-info'}`} 
                                    onClick={HandleSubmitBeneficiary}
                                >
                                    {loading1 ? (
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    ) : 'ADD BENEFICIARY'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AddNewBeneficiary;